import { createActionGroup, props } from '@ngrx/store';
import { notificationsFeatureKey } from './selectors';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const NotificationsActions = createActionGroup({
  source: notificationsFeatureKey,
  events: {
    showNotification: props<{
      message: string;
      action?: string;
      panelClass?: string;
      config?: MatSnackBarConfig;
    }>(),
  },
});
